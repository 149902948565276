////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(220, 52, 60, 1);
}
.bg-thirdColor {
  background-color: rgba(17, 31, 19, 1);
}
.bg-fourthColor {
  background-color: rgba(17, 31, 19, 1);
}
.bg-fifthColor {
  background-color: rgba(17, 31, 19, 0.71);
}
.bg-sixthColor {
  background-color: rgba(255, 235, 238, 1);
}
.bg-seventhColor {
  background-color: rgba(220, 52, 60, 1);
}
